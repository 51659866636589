<template>
    <div>
        <Navbar mode="solid" class="navbar" :show-btn-icon="true" :show-avatar="false" :show-btns="false"
            :btn-link-path="backPath" />
        <main class="main">
            <div class="wrapper">
                <div class="main--container">
                    <h1 class="page-title" v-t="'page.group_tour.payment.title'" />
                    <p class="page-description" v-t="'page.group_tour.payment.sub_title'" />
                    <form @submit.prevent="processPayment" class="form">
                        <h2 class="price--title" v-t="'page.group_tour.payment.section.details.title'" />
                        <table class="table">
                            <tr class="table--row">
                                <td v-t="'page.group_tour.payment.section.details.tour_date'" />
                                <td>
                                    {{ tourStartDate | date("date") }} –
                                    {{ tourEndDate | date("date")}}
                                </td>
                            </tr>
                            <tr class="table--row">
                                <td v-t="'page.group_tour.payment.section.details.number_of_travellers'" />
                                <td>{{ sharedRooms + singleRooms }}</td>
                            </tr>
                        </table>
                        <Button size="xs" :text="$t('common.details')" kind="secondary" class="btn-details"
                            :show-icon="true" type="button" icon-class="bx bxs-info-circle"
                            @click.native="openTravellerDetails()"></Button>
                        <Divider class="my-8 mobile" />

                        <h2 class="price--title mobile" v-t="'page.group_tour.payment.section.price_details.title'" />
                        <table class="table mobile">
                            <tr class="table--row--subtotal table--row" v-if="singleRooms > 0">
                                <td>
                                    {{ tour.single_room_price | currency(tour.currency, getCurrency,
                                                                        rates[`${tour.currency}_${getCurrency}`]) }}
                                    &#215; {{ $tc("page.group_tour.payment.section.price_details.single_rooms",singleRooms)
                                                                        }}
                                </td>
                                <td>
                                    {{ subTotalSingleRoom | currency(tour.currency, getCurrency,
                                                                        rates[`${tour.currency}_${getCurrency}`]) }}
                                </td>
                            </tr>
                            <tr class="table--row--subtotal table--row" v-if="sharedRooms > 0">
                                <td>
                                    {{ tour.shared_room_price | currency(tour.currency, getCurrency,
                                                                        rates[`${tour.currency}_${getCurrency}`]) }}
                                    &#215; {{$tc("page.group_tour.payment.section.price_details.shared_rooms",sharedRooms)
                                                                        }}
                                </td>
                                <td>
                                    {{
                                                                        (tour.shared_room_price * sharedRooms)
                                                                        | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                        }}
                                </td>
                            </tr>
                            <tr class="table--row--total table--row">
                                <td>Total</td>
                                <td>
                                    {{ totalPrice | currency(tour.currency, getCurrency,
                                                                        rates[`${tour.currency}_${getCurrency}`]) }}
                                </td>
                            </tr>
                        </table>
                        <Divider class="my-8" />
                        <h2 class="price--title" v-t="'page.group_tour.payment.section.cancellation_policy.title'" />
                        <div class="refund-cards">
                            <div class="refund-card">
                                <div class="refund-card-color-label refund-card-green"></div>
                                <div class="p-3 text">
                                    <h5 class="font-sans font-medium text-base-text">
                                        {{ $t("offer.cancellation.full") }} ·
                                        100%
                                    </h5>
                                    <p class="font-sans text-sm text-base-text">
                                        {{ fullRefundText }}
                                    </p>
                                </div>
                            </div>
                            <div class="refund-card">
                                <div class="refund-card-color-label refund-card-yellow"></div>
                                <div class="p-3 text">
                                    <h5 class="font-sans font-medium text-base-text">
                                        {{ $t("offer.cancellation.partial") }} ·
                                        {{ tour.partial_refund }}%
                                    </h5>
                                    <p class="font-sans text-sm text-base-text">
                                        {{ partialRefundText }}
                                    </p>
                                </div>
                            </div>
                            <div class="refund-card">
                                <div class="refund-card-color-label refund-card-red"></div>
                                <div class="p-3 text">
                                    <h5 class="font-sans font-medium text-base-text">
                                        {{ $t("offer.cancellation.no") }} · 0%
                                    </h5>
                                    <p class="font-sans text-sm text-base-text">
                                        {{ noRefundText }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Divider class="my-8" />
                        <div class="card-info-tile">
                            <div class="flex items-center">
                                <i class="mr-3 text-lg bx bxs-lock-alt text-black-base"></i>
                                <h4 class="mr-3 font-sans font-semibold">
                                    {{ $t("payment.tag") }}
                                </h4>
                            </div>
                            <div class="cards-icons-container">
                                <img class="h-8 mr-2 w-9" src="@/assets/img/credit-cards/Mastercard.svg" alt="" />
                                <img class="h-8 mr-2 w-9" src="@/assets/img/credit-cards/Visa.svg" alt="" />
                                <img class="h-8 mr-2 w-9" src="@/assets/img/credit-cards/Amex.svg" alt="" />
                            </div>
                        </div>
                        <InputText :label="`${$t('payment.cardholder.label')}`" class="input-field"
                            :placeholder="`${$t(
                                                                                                                                                                                                                                                                                'payment.cardholder.placeholder'
                                                                                                                                                                                                                                                                            )}`"
                            type="text" name="cardholder-name" :disabled="loading" :errorMsg="fullNameErrMsg"
                            :showErrMsg="$v.form.fullName.$error" @blur="$v.form.fullName.$touch()"
                            v-model="form.fullName" />
                        <!-- STRIPE ELEMENT -->
                        <div class="flex flex-wrap mt-4 -mx-2">
                            <div class="w-full p-2">
                                <div class="relative">
                                    <label for="card-element" class="stripe-label">
                                        {{ $t("payment.card.label") }}
                                    </label>
                                    <div id="card-element"></div>
                                    <div id="card-errors" role="alert"></div>
                                </div>
                            </div>
                        </div>
                        <Checkbox :showBookingLabel="true" :showDefaultLabel="false" :showErrMsg="$v.form.terms.$error"
                            @input="$v.form.terms.$touch()" v-model="form.terms" :error-msg="termsErrMsg" />
                        <Button :text="$t('common.cta.book_your_trip')" class="desktop-cta" kind="primary" size="lg"
                            type="submit" :loading="loading" />
                    </form>
                </div>
                <aside class="aside--container">
                    <div class="table--container">
                        <div class="tour--container">
                            <figure class="tour--img">
                                <img :src="tourPhoto" />
                            </figure>
                            <div>
                                <h2 class="tour--title">
                                    {{ tour.title }} · {{ tour.destination }}
                                </h2>
                                <div class="tour-card-rating">
                                    <Rating size="xxs" :is-static="true" :rating="tour.partner.reviews.rating" />
                                    <span class="tour--reviews">{{ $tc("reviews.n_reviews", tour.partner.reviews.count)
                                                                            }}</span>
                                </div>
                                <span class="tour-card-agency">

                                    {{ $t("common.offered_by", {agency: partnerFirstName}) }}
                                </span>
                            </div>
                        </div>
                        <Divider class="table--divider" />
                        <h3 class="font-semibold text-lg text-black-base"
                            v-t="'page.group_tour.payment.section.price_details.title'" />
                        <table class="table">
                            <tr class="table--row--subtotal table--row" v-if="singleRooms > 0">
                                <td>
                                    {{
                                                                        tour.single_room_price
                                                                        | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                        }}
                                    &#215; {{ $tc("page.group_tour.payment.section.price_details.single_rooms",sharedRooms)
                                                                        }}
                                </td>
                                <td>
                                    {{
                                                                        (tour.single_room_price * singleRooms)
                                                                        | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                        }}
                                </td>
                            </tr>
                            <tr class="table--row--subtotal table--row" v-if="sharedRooms > 0">
                                <td>
                                    {{
                                                                        tour.shared_room_price
                                                                        | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                        }}
                                    &#215; {{ $tc("page.group_tour.payment.section.price_details.shared_rooms",sharedRooms)
                                                                        }}
                                </td>
                                <td>
                                    {{
                                                                        (subTotalSharedRoom)
                                                                        | currency(tour.currency, getCurrency, rates[`${tour.currency}_${getCurrency}`])
                                                                        }}
                                </td>
                            </tr>
                            <tr class="table--row--total table--row">
                                <td>Total</td>
                                <td>
                                    {{ totalPrice | currency(tour.currency, getCurrency,
                                                                        rates[`${tour.currency}_${getCurrency}`]) }}
                                </td>
                            </tr>
                        </table>
                    </div>
                </aside>
            </div>
        </main>
        <div class="bottom-nav">
            <div class="bottom-nav-wrapper">
                <Button :loading="loading" kind="primary" @click.native="processPayment()" class="block h-12 px-8 w-full"
                    :text="$t('common.cta.book_your_trip')" />
            </div>
        </div>
        <div class="traveller--details" v-show="showTravellerForm">
            <Button icon-class="bx bx-x" shape="round" :show-text="false" type="button" class="btn-close"
                @click.native="closeTravellerDetails()" />
            <h2 class="sub--title" v-t="'page.group_tour.payment.dialog.title'" />
            <div class="form-input--container">
                <table class="table" v-for="(traveller, index) in travellers" :key="index">
                    <tr class="table--row">
                        <td></td>
                    </tr>
                    <tr class="table--row">
                        <td class="font-semibold">
                            {{ traveller.firstName }} {{ traveller.lastName }}
                            <span class="traveller--item--traveller--type--tag ml-2" v-if="traveller.lead"
                                v-t="'common.lead_traveller'" />
                        </td>
                    </tr>
                    <tr>
                        <td class="text-black-lighter text-sm font-medium flex-wrap inline-flex gap-2">

                            <span class="traveller--item--detail--tag">
                                <i class="bx bxs-cake bx-xs"></i>
                                {{ traveller.birthDate | date("date") }}
                            </span>

                            <span class="traveller--item--detail--tag">
                                <i class="bx bx-world bx-xs"></i>
                                {{ getNationalityByLang(traveller.nationality) }}
                            </span>

                            <span class="traveller--item--detail--tag" v-if="traveller.lead">
                                <i class="bx bxs-phone bx-xs"></i>
                                {{ traveller.phoneNumber }}
                            </span>

                            <span class="traveller--item--detail--tag" v-if="traveller.lead">
                                <i class="bx bxs-envelope bx-xs"></i>
                                {{ traveller.email }}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="availability--dialog" v-if="showAvailabilityDialog">
            <div class="flex gap-3">
                <i class="bx bxs-error-circle bx-sm text-red-base  mt-1"></i>
                <div class="availability--dialog--text-container">
                    <h2 class="sub--title" v-t="'page.group_tour.payment.availability_dialog.title'" />

                    <p>{{ $tc("page.group_tour.payment.availability_dialog.text", placesLeft, {text: recommendedAction}) }}
                    </p>
                    <div class="btn--container">
                        <Button kind="basic" :text="$t('common.cta.edit_travellers')" @click.native="goToDetailsPage()" />
                        <Button kind="primary" :text="$t('common.cta.find_another_tour')" @click.native="goToToursPage()" />
                    </div>
                </div>
            </div>

        </div>
        <BackgroundLayer v-if="showBackground" @click.native="closeTravellerDetails()" />
        <BackgroundLayer v-if="showDialogBackground" />
    </div>
</template>

<script>
import Button from "@/components/buttons/Button.vue";
import { mapGetters, mapActions } from "vuex";
import Divider from "@/components/dividers/Divider.vue";
import Rating from "@/components/review/Rating.vue";
import InputText from "@/components/inputs/InputText.vue";
const { required } = require("vuelidate/lib/validators");
import Checkbox from "@/components/checkboxes/Checkbox.vue";
import { loadStripe } from "@stripe/stripe-js";
import BackgroundLayer from "@/components/BackgroundLayer.vue";
import countries from "@/data/world/countries.json";
export default {
    name: "Payment",
    components: {
        Divider,
        Button,
        Rating,
        InputText,
        Checkbox,
        BackgroundLayer,
    },
    data() {
        return {
            countries,
            showDialogBackground: false,
            loading: false,
            form: {
                fullName: "",
                terms: "",
            },
            stripe: {},
            placesLeft: 0,
            showAvailabilityDialog: false,
            cardElement: {},
            showBackground: false,
            showTravellerForm: false,
            leadTraveller: undefined,
            otherTravellers: undefined,
            countriesOptions: [],
            fallBackImg: require("../../../../../assets/img/placeholders/placeholder.png"),
        };
    },
    validations: {
        form: {
            fullName: {
                required,
            },
            terms: {
                required,
                checked: (val) => {
                    return val;
                },
            },
        },
    },
    computed: {
        ...mapGetters("user", ["user", "isAuthenticated"]),
        ...mapGetters({
            getCurrency: "global/currency",
            travellers: "tour/travellers",
            singleRooms: "tour/singleRooms",
            sharedRooms: "tour/sharedRooms",
            tour: "tour/tour",
            startDate: "tour/startDate",
            endDate: "tour/endDate",
            rates: "exchange/fixedRates",
        }),
        recommendedAction() {
            if (this.placesLeft > 0) {
                return this.$t("page.group_tour.payment.availability_dialog.recommendation_double");
            }

            return this.$t("page.group_tour.payment.availability_dialog.recommendation_single");
        },
        partnerFirstName() {
            return this.$options.filters.agencyGenitive(
                this.tour.partner.first_name
            );
        },
        subTotalSharedRoom() {
            return this.tour.shared_room_price * this.sharedRooms;
        },
        subTotalSingleRoom() {
            return this.tour.single_room_price * this.singleRooms;
        },
        tourPhoto() {
            if (this.tour.image) {
                return this.app_file_base_path + this.tour.image;
            }

            return this.fallBackImg;
        },
        detailsPath() {
            let tourId = this.$route.query.id;
            let dateId = this.$route.query.date_id;
            let tourDate = this.$route.query.trip_date;
            let origin = this.$route.query.origin;
            return `/book/details?id=${tourId}&date_id=${dateId}&trip_date=${tourDate}&origin=${origin}`;
        },
        tourDate() {
            return this.$route.query.trip_date;
        },
        tourStartDate() {
            return this.$route.query.trip_date.split("|")[0];
        },
        tourEndDate() {
            return this.$route.query.trip_date.split("|")[1];
        },
        toursPath() {
            return `/tours`;
        },
        fullNameErrMsg() {
            let msg;
            if (!this.$v.form.fullName.required) {
                msg = this.$t("common.error.cardholder_required");
            }
            return msg;
        },
        termsErrMsg() {
            let msg;
            if (!this.$v.form.terms.required) {
                msg = this.$t("common.error.consent_required");
            }
            return msg;
        },
        app_file_base_path() {
            return process.env.VUE_APP_FILE_BASE_PATH;
        },
        fullRefundText() {
            let count = this.tour.full_refund_days;
            return this.$tc("offer.cancellation.text", count, { count: count });
        },
        partialRefundText() {
            let count = this.tour.partial_refund_days;
            return this.$tc("offer.cancellation.text", count, { count: count });
        },
        noRefundText() {
            let count = this.tour.no_refund_days;
            return this.$tc("offer.cancellation.text", count, { count: count });
        },
        totalPrice() {
            return this.subTotalSingleRoom + this.subTotalSharedRoom;
        },
        totalConvertedPrice() {
            let total = this.totalPrice * this.rates[`${this.tour.currency}_${this.getCurrency}`];
            return this.round(total);
        },
        backPath() {
            return `/book/accommodation?id=${this.$route.query.id}&date_id=${this.$route.query.date_id}&trip_date=${this.$route.query.trip_date}&origin=${this.$route.query.origin}`;
        },
    },
    methods: {
        ...mapActions({
            setSingleRooms: "request/setSingleRooms",
            setSingleRoom: "request/setSingleRoom",
            setSelectedAccommodations: "request/setSelectedAccommodations",
            setMealPlan: "request/setMealPlan",
            decreasePage: "request/decreasePage",
            increasePage: "request/increasePage",
            setIsContactPage: "request/setIsContactPage",
            setFirstSetting: "request/setFirstSetting",
            setFormData: "request/setFormData",
            createPaymentIntent: "tour/createPaymentIntent",
            confirmPayment: "tour/confirmPayment",
            getTour: "tour/getTour",
        }),
        round(value, precision = 0) {
            let multiplier = Math.pow(10, precision);
            return Math.round(value * multiplier) / multiplier;
        },
        getNationalityByLang(nationality) {
            let nationalityByLang = this.countriesOptions.filter(
                (option) => option.value === nationality
            );
            return nationalityByLang[0].name;
        },
        async setCard() {
            this.stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY, {
                locale: localStorage.getItem("lang") || "en",
            });
            this.cardElement = this.stripe.elements();
            const element = this.cardElement.create("card");
            element.mount("#card-element");
        },
        goToToursPage() {
            this.$router.push(this.toursPath);
        },
        goToDetailsPage() {
            this.$router.push(this.detailsPath);
        },
        async processPayment() {
            this.loading = true;
            try {
                this.$v.form.$touch();

                if (await this.isDateStillAvailable() === false) {
                    return false;
                }

                if (!this.$v.form.$invalid) {
                    let bookingDetails = {
                        tour_id: this.tour.id,
                        firstname: this.leadTraveller.firstName,
                        lastname: this.leadTraveller.lastName,
                        email: this.leadTraveller.email,
                        nationality: this.leadTraveller.nationality,
                        dob: this.leadTraveller.birthDate,
                        phone: this.leadTraveller.phoneNumber,
                        other_travelers: JSON.stringify(this.otherTravellers),
                        tour_date: this.tourDate,
                        single_rooms: this.singleRooms,
                        shared_rooms: this.sharedRooms,
                        currency: this.getCurrency,
                        total_price: this.totalConvertedPrice,
                        lang: localStorage.getItem("lang"),
                    };

                    const paymentIntent = await this.createPaymentIntent(
                        bookingDetails
                    );

                    if (!paymentIntent) return false;
                    const tourBookingId = paymentIntent.data.tour_booking.id;
                    const transactionId =
                        paymentIntent.data.tour_booking.transaction_id;
                    const cardElement = this.cardElement.getElement("card");

                    const billingDetails = {
                        name: `${this.leadTraveller.firstName} ${this.leadTraveller.lastName}`,
                        email: this.leadTraveller.email,
                        phone: this.leadTraveller.phoneNumber,
                    };

                    const { paymentMethod, error } =
                        await this.stripe.createPaymentMethod({
                            type: "card",
                            card: cardElement,
                            billing_details: billingDetails,
                        });

                    if (error) {
                        this.loading = false;
                        this.$notify({
                            type: "error",
                            title: this.$t("payment.card.error"), //Check your credit card details
                        });
                        return;
                    }

                    const confirmStripPayment =
                        await this.stripe.confirmCardPayment(
                            paymentIntent.data.tour_booking.payment_intent
                                .client_secret,
                            { payment_method: paymentMethod.id }
                        );

                    if (
                        confirmStripPayment.paymentIntent.status !== "succeeded"
                    ) {
                        this.loading = false;
                        this.$notify({
                            type: "error",
                            title: "Error. Contact support",
                        });
                    } else {
                        const data = {
                            tour_id: this.tour.id,
                            tour_booking_id: tourBookingId,
                            transaction_id: transactionId,
                            lang: localStorage.getItem("lang"),
                        };

                        const paymentWebhook = await this.confirmPayment(data);

                        if (paymentWebhook.data.response === "success") {
                            this.loading = false;

                            localStorage.setItem("show_thanks", "true");
                            await this.$router.push("/payment/thank-you");
                        }
                    }
                }
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.$notify({
                    type: "error",
                    title: this.$t("common.error.payment"),
                });
            } finally {
                this.loading = false;
            }
        },
        async isDateStillAvailable() {
            let tour = await this.getTour(this.tour.id)
                .then((res) => res.data.tour)
                .catch((err) => {
                    console.log(err);
                });
            let dateId = Number(this.$route.query.date_id);
            let date = tour.dates.filter((date) => date.id === dateId);
            if (date[0].places_left < this.travellers.length) {
                this.placesLeft = date[0].places_left;
                this.showDialogBackground = true;
                this.showAvailabilityDialog = true;

                return false;
            }

            return true;
        },
        openTravellerDetails() {
            this.showBackground = this.showTravellerForm = true;
        },
        closeTravellerDetails() {
            this.showBackground = this.showTravellerForm = false;
        },
    },
    created() {
        let lang = localStorage.getItem("lang");
        this.countries.forEach((country) => {
            this.countriesOptions.push({
                value: country["en"],
                name: country[lang],
                selected: false,
                disabled: false,
            });
        });
    },
    async mounted() {
        await this.setCard();
        let lead = this.travellers.filter(
            (traveller) => traveller.lead === true
        );
        this.otherTravellers = this.travellers.filter(
            (traveller) => traveller.lead === false
        );

        this.leadTraveller = lead[0];
    },
};
</script>

<style scoped>
.navbar {
    @apply border-none;
}

.main {
    @apply pt-16 pb-32 px-4 relative;
}
.btn-details {
    @apply ml-auto flex;
}
.tour--img {
    @apply rounded-lg overflow-hidden min-w-20 w-20 h-20;
    @apply relative;
}

.tour--img img {
    @apply absolute w-full h-full object-cover;
}

.tour--container {
    @apply flex gap-4;
}
.tour--title {
    @apply font-semibold text-black-base;
}

.tour-card-agency {
    @apply text-xs font-medium text-black-lighter;
    @apply flex items-center gap-1.5 mt-0.5;
}

.tour--reviews {
    @apply text-xs flex items-center;
}

.wrapper {
    @apply relative transform -translate-x-1/2 max-w-screen-md left-1/2;
    @apply md:grid md:grid-cols-12 md:gap-12;
}

.main--container {
    @apply md:col-span-7;
    @apply max-w-xl w-full md:w-auto;
    @apply relative left-1/2 md:left-auto transform -translate-x-1/2 md:translate-x-0;
}

.page-title {
    @apply font-bold text-3xl text-black-base;
}

.page-description {
    @apply text-black-lighter mb-12;
}

.price--title {
    @apply font-semibold text-black-base text-lg;
    @apply flex items-center gap-1;
}

.aside--container {
    @apply col-span-5;
    @apply self-start hidden md:block;
}
.table--container {
    @apply border-grey-base rounded-xl px-4 py-4 border;
    @apply sticky top-0 w-full;
}

.mobile {
    @apply block md:hidden;
}

.tour-card-rating {
    @apply flex gap-1.5 items-center;
}

.table {
    @apply w-full mt-3;
}

.table--divider {
    @apply my-4;
}

.table--row {
    @apply flex justify-between;
    @apply w-full pb-3 text-black-base;
}

.table--row--total {
    @apply font-semibold border-t border-grey-base pt-2.5;
}

.bottom-nav {
    @apply fixed bottom-0 left-0 w-full p-4 bg-white border-t border-grey-base sm:mt-16;
    @apply block sm:hidden;
}

.bottom-nav-wrapper {
    @apply relative flex items-center justify-between max-w-screen-sm transform -translate-x-1/2 left-1/2;
}

.desktop-cta {
    @apply hidden sm:flex h-12 px-8 w-full mt-8;
}

.refund-full {
    @apply bg-green-dark;
}

.refund-cards {
    @apply grid gap-4 mt-6;
}

.refund-card {
    @apply rounded-md  overflow-hidden border border-grey-base shadow-sm flex;
}

.refund-card-green {
    @apply bg-green-dark;
}

.refund-card-yellow {
    @apply bg-yellow-base;
}

.refund-card-red {
    @apply bg-red-base;
}

.refund-card-color-label {
    @apply w-2 h-full;
}

.stripe-label {
    @apply mb-3 inline-block font-sans font-semibold text-black-base;
}

.card-info-tile {
    @apply inline-flex mb-6 items-center px-3 py-3 rounded-lg w-full xs:w-initial;
    @apply bg-blue-lighter text-black-base;
}

.cards-icons-container {
    @apply inline-flex justify-between;
}

.input-expiry-date {
    @apply w-calc-14/24;
}

.input-cvv {
    @apply w-calc-10/24;
}

.StripeElement {
    @apply leading-tight w-full border px-3 py-4 border-grey-dark rounded-lg  text-black-base placeholder-black-lightest;
}

.StripeElement--focus {
    @apply border-teal-base ring-teal-lighter ring-2;
}

.StripeElement--invalid {
    @apply border-red-base ring-red-lighter ring-2;
}

.availability--dialog,
.traveller--details {
    @apply fixed left-1/2 transform -translate-x-1/2 top-1/2 -translate-y-1/2;
    @apply z-50 h-full md:h-auto overflow-hidden md:rounded-xl;
    @apply bg-white md:max-w-screen-sm w-full;
    @apply border border-grey-base py-12 px-4 sm:px-16;
    @apply overflow-y-auto;
}

.btn--container {
    @apply flex gap-4 pt-6 flex-wrap;
}

.btn--container > .btn {
    @apply w-full sm:w-initial;
}
.btn-close {
    @apply flex ml-auto;
}
.sub--title {
    @apply font-semibold text-2xl text-black-base;
}

.traveller--item--traveller--type--tag {
    @apply bg-violet-light bg-opacity-75 rounded-full text-violet-dark font-semibold;
    @apply text-xxs uppercase px-2.5 py-0.5 inline-block;
}

.traveller--item--detail--tag {
    @apply rounded-md px-2 py-1 bg-grey-base bg-opacity-50;
    @apply inline-flex items-center gap-1;
    @apply text-sm font-medium text-black-light;
}

.availability--dialog--text-container {
    @apply pr-9;
}
.availability--dialog--text-container p {
    @apply mt-6 text-black-base;
    @apply whitespace-pre-line;
}
</style>
